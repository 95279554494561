// Jquery functions
(function($) {
	// grid
	baselineGrid();
	// LazyLoading
	//img
	var myLazyLoad = new LazyLoad({
		elements_selector: "[data-src], [data-srcset], [data-bg], [data-sizes]",
		load_delay: 800,
		callback_set: function(el) {
			if (typeof $(el).attr("data-src") !== typeof undefined && $(el).attr("data-src") !== false) {
				$(el).removeAttr("data-src");
			}
			if (typeof $(el).attr("data-srcset") !== typeof undefined && $(el).attr("data-srcset") !== false) {
				$(el).removeAttr("data-srcset");
			}
			if (typeof $(el).attr("data-bg") !== typeof undefined && $(el).attr("data-bg") !== false) {
				$(el).removeAttr("data-bg");
			}
			if (typeof $(el).attr("data-sizes") !== typeof undefined && $(el).attr("data-sizes") !== false) {
				$(el).removeAttr("data-sizes");
			}
			objectFitImages(el);
		}
	});
	$("[data-target]").click(function() {
		var targetElement = $($(this).data("target"));
		var targetBottomOffset = $(this).data("bottom-offset");
		var targetTopOffset = $(this).data("top-offset");
		moveToHtmlTarget(targetElement, targetTopOffset, targetBottomOffset);
	});
	function moveToHtmlTarget(element, topOffset, bottomOffset) {
		var offset = element.offset().top;

		if (topOffset > 0) {
			offset = offset - topOffset;
		}

		if (bottomOffset > 0) {
			offset = offset + bottomOffset;
		}

		$("html, body").animate(
			{
				scrollTop: offset
			},
			1000
		);

		return this;
	}
	// iframe and table (wysiwyg editor)
	$(".entry-content iframe").each(function() {
		if (
			$(this)
				.attr("src")
				.indexOf("youtube") != -1 ||
			$(this)
				.attr("src")
				.indexOf("vimeo") != -1
		) {
			$(this)
				.addClass("embed-responsive-item")
				.wrap('<div class="embed-responsive embed-responsive-16by9"></div>');
			$(this).removeAttr("width");
			$(this).removeAttr("height");
		}
	});
	$(".entry-content table").each(function() {
		$(this)
			.addClass("table")
			.wrap('<div class="table-responsive"></div>');
	});
	function baselineGrid() {
		var gridToggle = $(".baseline-toggle");

		gridToggle.click(function() {
			$("html").toggleClass("js-baseline");
		});
	} 
})( jQuery );
